<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">Stock De Productos</h4>
      </template>
      <template v-slot:headerAction>
        <b-button
          size="sm"
          variant=" iq-bg-primary"
          class="mr-2"
          :to="{ name: 'products.stock-register' }"
        >
          <i class="d-block d-sm-none ri-pie-chart-2-line"></i>
          <span class="d-none d-sm-block d-md-block"> Ingreso/Egreso de Productos</span>
        </b-button>
        <b-button
          size="sm"
          :href="`${baseUrl}/api/product/stock/record/export`"
          v-b-tooltip.hover
          title="Descargar excel"
          variant=" iq-bg-success"
          target="_blank"
          rel="noopener noreferrer"
          ><i class="ri-download-2-line ml-1"></i>Inventario</b-button
        >
      </template>
      <template v-slot:body>
        <b-row>
          <b-col md="4">
            <!-- SELECT UBICACIONES -->
            <div class="form-group">
              <b-form-group label-for="Ubicaciones" label="Ubicaciones">
                <v-select
                  label="name"
                  v-model="ubication_id"
                  placeholder="Todos"
                  :options="ubicaciones"
                  :components="{ Deselect }"
                >
                  <template #no-options="{}"> Sin Resultados... </template>
                </v-select>
              </b-form-group>
            </div>
          </b-col>
          <b-col md="4">
            <!-- SELECT AREA -->
            <div class="form-group">
              <b-form-group label-for="Área" label="Área">
                <v-select
                  v-model="area_id"
                  label="name"
                  placeholder="Todos"
                  :options="areas"
                  :components="{ Deselect }"
                >
                  <template #no-options="{}"> Sin Resultados... </template>
                </v-select>
              </b-form-group>
            </div>
          </b-col>
          <b-col md="4" class="ml-auto">
            <!-- INPUT SEARCH -->
            <div class="form-group">
              <b-form-group label="Buscar:" label-for="filter-input">
                <b-input-group>
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Buscar..."
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="table-responsive">
            <b-table
              hover
              :busy="
                !Array.isArray(tableData) || tableData.length === 0
                  ? true
                  : false || isBusy == true
              "
              :items="tableData"
              :fields="columns"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <div v-if="isBusy">
                    <b-spinner type="grow" class="align-middle"></b-spinner>
                    <strong> Cargando datos...</strong>
                  </div>
                  <span class="h5" v-else>Sin registros</span>
                </div>
              </template>
              <template v-slot:cell(options)="data">
                <div class="row">
                  <div class="col-6">
                    <b-button
                      v-b-tooltip.hover
                      title="Ver detales"
                      variant=" iq-bg-primary"
                      size="sm"
                      @click="detalles(data.item)"
                      ><i class="ri-eye-line ml-1"></i>
                    </b-button>
                  </div>
                  <div class="ml-1">
                    <b-button
                      :href="`${baseUrl}/api/product/stock/record/${data.item.id}/export`"
                      v-b-tooltip.hover
                      title="Descargar excel"
                      variant=" iq-bg-success"
                      size="sm"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><i class="ri-download-2-line ml-1"></i
                    ></b-button>
                  </div>
                </div>
              </template>
            </b-table>
            <b-pagination
              class="mt-3"
              v-model="paginate.current_page"
              :total-rows="paginate.total"
              :per-page="paginate.per_page"
              :page-count="paginate.per_page"
              @input="changePage"
              first-number
              pills
              size="sm"
              align="center"
            ></b-pagination>
          </b-col>
        </b-row> </template></iq-card
  ></b-container>
</template>
</iq-card>
<div>
  <b-modal
  id="my-location"
  ref="location"
  size="lg"
  hide-footer
  title="Using Component Methods"
  >
  <div class="d-block text-center">
    <b-row>
      <b-col md="12" class="table-responsive">
        <b-table hover :items="egresos" :fields="egreso"> </b-table>
      </b-col>
    </b-row>
  </div>
  <b-button
  class="mt-3 float-right"
  variant="outline-danger"
  @click="$bvModal.hide('my-location')"
  >Cerrar</b-button
  >
</b-modal>
</div>
</b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "ListProductosStock",
  async created() {
    this.$store.commit("SET_BREADCRUMB", this.items);
    core.index();
    this.isBusy = true;
    this.debouncedGetAnswer = _.debounce(this.setUbication, 500);
    await this.listArea();
    await this.listUbication();
    await this.setUbication();
    this.isBusy = false;
    this.areas = this.getAreaList;
    this.ubicaciones = this.getUbication;
  },
  watch: {
    ubication_id() {
      this.setUbication();
    },
    area_id() {
      this.setUbication();
    },
    filter() {
      this.debouncedGetAnswer();
    },
  },
  data() {
    return {
      Deselect: {
        render: (createElement) => createElement("small", "❌Todos"),
      },
      columns: [
        { label: "SKU", key: "sku", class: "text-center" },
        { label: "Marca", key: "marca", class: "text-center" },
        { label: "Nombre", key: "product", class: "text-center" },
        { label: "Modelo", key: "model", class: "text-center" },
        { label: "Color", key: "color", class: "text-center" },
        { label: "Stock Total", key: "stockTotal", class: "text-center" },
        { label: "Acciones", key: "options", class: "text-center" },
      ],
      isBusy: false,
      items: [
        {
          html: "Inicio",
          to: "/",
        },
        {
          text: "Stock De Productos",
          active: true,
        },
      ],
      egreso: [
        { label: "UBICACIÓN", key: "ubication", class: "text-center" },
        { label: "CANTIDAD", key: "quantity", class: "text-center" },
      ],
      baseUrl: process.env.VUE_APP_API_URL,
      egresos: [],
      rows: [],
      perPage: 10,
      currentPage: 1,
      products: [],
      areas: [],
      ubicaciones: [],
      ubication_id: null,
      area_id: null,
      totalRows: 1,
      filter: null,
      paginate: {},
    };
  },
  methods: {
    ...mapActions({
      listArea: "area/listArea",
      listUbication: "ubication/listUbication",
      listProductsStock: "products/listProductsStock",
    }),
    async setUbication() {
      if (this.ubication_id !== null || this.area_id !== null) {
        this.isBusy = true;
        let payload = {
          area: this.area_id ? this.area_id.id : null,
          ubicacion: this.ubication_id ? this.ubication_id.id : null,
          search: this.filter ? this.filter : null,
          perPage: this.perPage,
          currentPage: 1,
        };
        const res = await this.listProductsStock(payload);
        this.rows = res.data;
        this.paginate = res.meta;
        this.isBusy = false;
      } else {
        this.isBusy = true;
        const res = await this.listProductsStock({
          type: 1,
          currentPage: 1,
          perPage: this.perPage,
          search: this.filter ? this.filter : null,
        });
        this.rows = res.data;
        this.paginate = res.meta;
        this.isBusy = false;
      }
    },
    async changePage(value) {
      this.isBusy = true;
      let payload = {
        area: this.area_id ? this.area_id.id : null,
        ubicacion: this.ubication_id ? this.ubication_id.id : null,
        perPage: this.perPage,
        currentPage: value ? value : 1,
        type: this.ubication_id !== null || this.area_id !== null ? 2 : 1,
      };
      const res = await this.listProductsStock(payload);
      this.rows = res.data;
      this.paginate = res.meta;
      this.isBusy = false;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    detalles(item) {
      this.$store.commit("products/SET_PRODUCTS_STOCK_DETAILL", item);
      this.$router.replace(`/products/stock/details/${item.id}`);
    },
  },
  computed: {
    ...mapGetters({
      getAreaList: "area/getAreaList",
      getUbication: "ubication/getUbication",
    }),
    tamaño() {
      return 20;
    },
    tableData() {
      const res = this.rows.map((item) => ({
        id: item.id,
        marca: item.marca,
        color:
          item.attributes[1] == null
            ? "Sin color"
            : item.attributes[1].attr === "color"
            ? item.attributes[1].value
            : item.attributes[0] == undefined
            ? "Sin color"
            : item.attributes[0].value,
        model:
          item.attributes[0] == null
            ? "Sin modelo"
            : item.attributes[0].attr === "model"
            ? item.attributes[0].value
            : item.attributes[1] == undefined
            ? "Sin modelo"
            : item.attributes[0].value,
        area: item.area_count,
        product: item.name,
        sku: item.sku,
        stockTotal: item.balance,
      }));
      return res;
    },
  },
};
</script>
<style>
</style>
